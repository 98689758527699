import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Experiencies = () => {
  const images = [];
  for (let i = 1; i <= 10; i++) {
    images.push(`/images/experiencias/exp${i}.jpg`);
  }

  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const interval = setInterval(() => {
      slider.slickNext();
    }, 6000); // slide duration

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    nextArrow: (
      <div>
        <div className="flex text-pink-700">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 hover:text-pink-500 transition-all duration-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="flex text-pink-700">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 hover:text-pink-500 transition-all duration-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="experiencies">
      <div className="bg-gradient-to-b from-white to-[#CAF4FF] h-8"></div>
      <div className="w-full py-10 sm:py-20 h-full bg-[#CAF4FF]">
        <h2 className="text-5xl sm:text-7xl font-extrabold pt-4 mb-4 text-pink-400 text-center sm:text-center w-2/3 mx-auto">
          Nuestras experiencias
        </h2>
        <div className="w-9/12 sm:w-11/12 mx-auto my-8">
          <Slider
            ref={sliderRef}
            {...settings}
            className="h-full flex gap-2 outline-none ring-0 focus:ring-0 focus:outline-none"
          >
            {images.map((src, index) => (
              <div key={index} className="flex rounded-2xl h-96 px-2">
                <img
                  src={src}
                  alt={`Experience ${index + 1}`}
                  className="w-full h-full object-cover transition duration-300 ease-in-out transform rounded-2xl"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Experiencies;
