import React, { useEffect, useState } from "react";

const HubSpotForm = ({ portalId, formId, region }) => {
  const [loading, setLoading] = useState(true); // Estado para controlar la carga

  useEffect(() => {
    // Comprobar si el script ya está cargado o el formulario ya está creado
    if (
      !document.querySelector(
        'script[src="//js.hsforms.net/forms/embed/v2.js"]'
      )
    ) {
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region,
            portalId,
            formId,
            target: "#hubspotForm",
            onFormReady: () => setLoading(false), // Desactiva loading cuando el formulario está listo
          });
        }
      });

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [portalId, formId, region]);

  return <div id="hubspotForm" className={loading ? "hidden" : ""}></div>;
};

export default HubSpotForm;
