import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4 bg-white rounded-lg shadow-md overflow-hidden">
      <button
        className="flex justify-between items-center w-full text-left px-6 py-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-gray-900">{question}</span>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <FaChevronDown className="text-pink-500" />
        </motion.div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="px-6 pb-4 text-gray-600">{answer}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ = ({ faqs }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredFaqs = faqs?.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="max-w-3xl mx-auto">
      <div className="mb-8">
        <input
          type="text"
          placeholder="Buscar preguntas frecuentes..."
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {filteredFaqs?.length > 0 ? (
        filteredFaqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))
      ) : (
        <p className="text-center text-gray-600">
          No se encontraron preguntas que coincidan con tu búsqueda.
        </p>
      )}
      <div className="mt-8 text-center">
        <p className="text-gray-600 mb-4">
          ¿No encontraste lo que buscabas? Contáctanos para más información.
        </p>
        <a
          href="/contacto"
          className="inline-block bg-pink-500 text-white px-6 py-2 rounded-full hover:bg-pink-600 transition duration-300"
        >
          Contáctanos
        </a>
      </div>
    </div>
  );
};

export default FAQ;
