import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import ModelsPage from "./Pages/ModelsPage";
import AboutPage from "./Pages/AboutPage";
import ContactPage from "./Pages/ContactPage";
import FranchisePage from "./Pages/FranchisePage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<AboutPage />} />
        <Route path="/modelos" element={<ModelsPage />} />
        <Route path="/contacto" element={<ContactPage />} />
        <Route path="/negocio" element={<FranchisePage />} />
        <Route path="/politica-de-privacidad" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
};

export default App;
