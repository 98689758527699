import React from "react";

function Health() {
  // Images

  // const images = [
  //   {
  //     id: 1,
  //     src: "/images/health1.png",
  //     alt: "Cotton Candy Machine",
  //   },
  //   {
  //     id: 2,
  //     src: "/images/health2.png",
  //     alt: "Cotton Candy Machine",
  //   },
  //   {
  //     id: 3,
  //     src: "/images/health3.png",
  //     alt: "Cotton Candy Machine",
  //   },
  //   {
  //     id: 4,
  //     src: "/images/health4.png",
  //     alt: "Cotton Candy Machine",
  //   },
  // ];

  const images2 = [
    {
      id: 1,
      src: "/images/lactose-free.png",
      alt: "Cotton Candy Machine",
    },
    {
      id: 2,
      src: "/images/gluten-free.png",
      alt: "Cotton Candy Machine",
    },
    {
      id: 3,
      src: "/images/vegan.png",
      alt: "Cotton Candy Machine",
    },
  ];

  return (
    <div id="about">
      <div className="bg-gradient-to-b from-secondary to-tertiary h-8"></div>
      <div className="text-center bg-tertiary">
        <div className="flex justify-center items-center flex-col sm:flex-row sm:gap-12">
          <div className="w-3/4 sm:w-2/5 sm:my-20 p-4 sm:text-left ">
            <h2 className="text-5xl sm:text-7xl font-extrabold pt-4 pb-12 text-pink-400 text-center sm:text-left">
              Información nutricional
            </h2>
            <div className="relative h-40 sm:w-[32rem] rounded-full p-4 my-20">
              <div className="absolute h-32 w-32 sm:h-40 sm:w-40 bg-white rounded-full -top-14 -left-6 sm:-top-6 sm:-left-20"></div>
              <div className="absolute h-32 w-32 sm:h-48 sm:w-48 bg-white rounded-full top-8 -left-14 sm:-left-5 sm:-top-20"></div>
              <div className="absolute h-32 w-32 sm:h-48 sm:w-48 bg-white rounded-full bottom-28 right-20 sm:right-40 sm:bottom-16"></div>
              <div className="absolute h-36 w-56 sm:h-44 sm:w-44 bg-white rounded-full sm:bottom-8 sm:-left-10 sm:top-20"></div>
              <div className="absolute h-32 w-32 sm:h-56 sm:w-56 bg-white rounded-full -top-14 -right-6 sm:-top-20 sm:-right-0"></div>
              <div className="absolute h-36 w-36 sm:h-48 sm:w-48 bg-white rounded-full top-4 -right-8 sm:top-14 sm:right-10"></div>
              <div className="absolute h-44 w-44 sm:h-48 sm:w-48 bg-white rounded-full -bottom-24 right-14 sm:-bottom-22 sm:right-40"></div>
              <div className="absolute h-40 w-40 sm:h-48 sm:w-48 bg-white -bottom-24 -left-4 rounded-full sm:left-20 sm:-top-20"></div>
              <div className="absolute h-32 w-32 sm:h-56 sm:w-56 bg-white rounded-full top-28 -right-4 sm:left-10 sm:top-14"></div>
              <div className="absolute inset-0 flex items-center text-pink-300 font-bold text-2xl sm:text-3xl text-center">
                <div className="mt-10 text-center sm:text-left">
                  <p className="text-2xl sm:text-3xl">
                    Algodón elaborado con la más alta calidad de azúcar.
                  </p>
                  <br></br>
                  <ul className="list-disc list-inside text-left pl-4 text-2xl sm:text-3xl">
                    <li>Sin lactosa</li>
                    <li>Sin gluten</li>
                    <li>Sin aspartamo</li>
                    <li>Vegano</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="bg-white rounded-2xl shadow-md p-4 mt-10">
              <p className="text-xl sm:text-3xl text-gray-700">
                Algodón elaborado con la más alta calidad de azúcar.
              </p>
              <br></br>
              <ul className="list-disc list-inside text-left p-4">
                <li className="text-xl sm:text-3xl text-gray-700">
                  Sin lactosa
                </li>
                <li className="text-xl sm:text-3xl text-gray-700">
                  Sin gluten
                </li>
                <li className="text-xl sm:text-3xl text-gray-700">
                  Sin aspartamo
                </li>
                <li className="text-xl sm:text-3xl text-gray-700">Vegano</li>
              </ul>
            </div> */}
          </div>

          <div className="w-4/6 sm:w-1/4 mt-8 mb-10">
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-2">
              {images2.map((image) => (
                <div
                  key={image.id}
                  className="w-full h-full flex justify-center items-center"
                >
                  <img src={image.src} alt={image.alt} className="w-5/6" />
                </div>
              ))}
              <img
                src="/images/aspartame.png"
                alt="aspartame-free"
                className="w-full"
              />
            </div>
          </div>

          {/* Grid with 4 clouds */}
          {/* <div className="w-4/6 sm:w-1/4 mt-8 mb-10">
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-2">
              <div className="w-full h-full flex justify-center items-center">
                <div className="w-24 h-24 sm:w-36 sm:h-36 bg-green-100 rounded-full shadow-md flex justify-center items-center">
                  <p className="text-2xl sm:text-4xl text-green-600">0</p>
                </div>
              </div>

              <div className="w-full h-full flex justify-center items-center">
                <div className="w-24 h-24 sm:w-36 sm:h-36 bg-yellow-100 rounded-full shadow-md flex justify-center items-center">
                  <p className="text-2xl sm:text-4xl text-yellow-600">0</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Health;
