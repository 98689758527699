import React, { useEffect } from "react";
import About from "./About";
import NavBar from "./Navbar";
import Health from "./Health";
import Contact from "./Contact";
import Footer from "./Footer";
import Models from "./Models";
import "./Home.css";
import Instagram from "./Instagram";
import Experiencies from "./Experiencies";
import FaqPage from "./FaqPage";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Este efecto se ejecutará solo cuando el componente se monte

  return (
    <div id="home">
      <NavBar currentPage="home" />
      <div className="bg-primary w-full h-screen flex justify-center items-center">
        {/* <div className=" w-full h-screen flex justify-center items-center">
        <div className="absolute w-full h-full bg-cover bg-center z-0">
          <img
            src="/images/background.jpg"
            alt="Cotton Candy"
            className="w-full h-full object-cover"
          />
        </div> */}
        <div className="w-11/12 sm:w-5/6 flex flex-col sm:flex-row justify-center items-center gap-12 text-center sm:text-left">
          {/* <p className="text-5xl sm:text-8xl font-bold text-gray-800 -mt-64 sm:mt-0">
            Magic Cotton Candy
          </p> */}
          <div className="w-full flex flex-col justify-center">
            <img
              src="/images/hero.png"
              alt="Brand Logo"
              className="w-11/12 sm:w-full -mt-64 sm:mt-14 mx-auto sm:mr-0"
            />
            {/* <h1 className="text-4xl sm:text-4xl font-extrabold text-yellow-300 text-center sm:text-right">
              {" "}
              La magia que endulza
              src="/images/cottons/cotton10.png"
            </h1> */}
          </div>

          <div className="w-full flex justify-center items-center">
            {/* <img
              src="/images/flores/Flor.png"
              alt="Cotton Candy"
              className="absolute w-8/12 sm:w-1/3 animate-spin-slow mt-72 sm:mt-14"
            /> */}
            <img
              src="/images/flores/Flor.png"
              alt="Cotton Candy"
              className="absolute w-2/3 sm:w-1/3 animate-swing mt-60 sm:mt-14"
            />
          </div>
        </div>
      </div>

      <About />
      <Health />
      <Models />
      <Experiencies />
      <Instagram />
      {/* <FaqPage /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
