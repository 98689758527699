import React, { useEffect } from "react";
import NavbarPages from "../Components/NavbarPages";
import Footer from "../Components/Footer";

function ModelsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const images = [
  //   {
  //     src: "/images/cottons/cotton2.png",
  //     alt: "Gallery",
  //     description: "Flor de Algodón",
  //   },
  //   {
  //     src: "/images/cottons/cotton3.png",
  //     alt: "Gallery",
  //     description: "Mariposa Gris",
  //   },
  //   {
  //     src: "/images/cottons/cotton4.png",
  //     alt: "Gallery",
  //     description: "Mariposa Azul",
  //   },
  //   {
  //     src: "/images/cottons/cotton5.png",
  //     alt: "Gallery",
  //     description: "Trebol Amarillo",
  //   },
  //   {
  //     src: "/images/cottons/cotton6.png",
  //     alt: "Gallery",
  //     description: "Trebol Rosa",
  //   },
  //   {
  //     src: "/images/cottons/cotton7.png",
  //     alt: "Gallery",
  //     description: "Amor Azul",
  //   },
  //   {
  //     src: "/images/cottons/cotton8.png",
  //     alt: "Gallery",
  //     description: "Nube Florar",
  //   },
  //   {
  //     src: "/images/cottons/cotton9.png",
  //     alt: "Gallery",
  //     description: "Amor de Algodón",
  //   },
  //   {
  //     src: "/images/cottons/cotton10.png",
  //     alt: "Gallery",
  //     description: "Flor de Rosas",
  //   },
  // ];

  const images2 = [
    {
      src: "/images/flores/Flor.png",
      alt: "Gallery",
      description: "Flor de Algodón",
    },
    {
      src: "/images/flores/Flor2.png",
      alt: "Gallery",
      description: "Flor de Algodón",
    },
    {
      src: "/images/flores/Flor3.png",
      alt: "Gallery",
      description: "Nube de Algodón",
    },
    {
      src: "/images/flores/Flor4.png",
      alt: "Gallery",
      description: "Nube de Algodón",
    },
    {
      src: "/images/flores/Flor5.png",
      alt: "Gallery",
      description: "Nube de Algodón",
    },
    {
      src: "/images/flores/Flor6.png",
      alt: "Gallery",
      description: "Nube de Algodón",
    },
    {
      src: "/images/flores/Flor7.png",
      alt: "Gallery",
      description: "Flor de Algodón",
    },
    {
      src: "/images/flores/Flor8.png",
      alt: "Gallery",
      description: "Corazón de Algodón",
    },
    {
      src: "/images/flores/Flor9.png",
      alt: "Gallery",
      description: "Corazón de Algodón",
    },
    {
      src: "/images/flores/Flor10.png",
      alt: "Gallery",
      description: "Corazón de Algodón",
    },
    {
      src: "/images/flores/Flor11.png",
      alt: "Gallery",
      description: "Flor de Algodón",
    },
    {
      src: "/images/flores/Flor12.png",
      alt: "Gallery",
      description: "Flor de Algodón",
    },
  ];

  return (
    <div>
      <NavbarPages currentPage="models" />

      <div className="flex justify-center pt-28 flex-col rounded-lg p-8">
        <h1 className="text-5xl sm:text-6xl uppercase font-black text-pink-500 text-center mt-4 sm:mt-8 mb-8">
          Nuestros Modelos
        </h1>
        <p className="text-2xl text-gray-700 text-center w-full sm:w-1/2 mx-auto mb-12">
          ¡Te damos la bienvenida a nuestra encantadora variedad de modelos de
          algodón de azúcar! Cada uno de ellos tiene su{" "}
          <span className="text-pink-500 font-bold">propia historia</span> y
          encanto especial. Desde los{" "}
          <span className="text-pink-500 font-bold">tonos vibrantes</span> hasta
          los{" "}
          <span className="text-pink-500 font-bold">sabores deliciosos</span>,
          estamos seguros de que encontrarás el algodón de azúcar perfecto para
          ti. ¡Explora nuestra selección y déjate llevar por la diversión y la
          dulzura!
        </p>

        <div className="grid grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-8 sm:w-5/6 sm:mx-auto">
          {images2.map((image, index) => (
            <div key={index} className="relative rounded-lg">
              <img
                src={image.src}
                alt={image.alt}
                className="flex sm:w-3/4 w-full h-[19vh] sm:h-[24vh] xl:h-[32vh] transition duration-300 ease-in-out transform hover:scale-105 object-cover mx-auto"
              />
              <div className="bg-pink-100 rounded-lg items-center justify-center w-3/4 mx-auto mt-2 mb-4">
                <h1 className="text-2xl font-bold text-center text-pink-400 p-2">
                  {image.description}
                </h1>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ModelsPage;
