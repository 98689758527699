import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Instagram = () => {
  const links = [
    {
      src: "https://www.instagram.com/reel/C4k97mXuNU4/embed/",
      alt: "Post",
    },
    {
      src: "https://www.instagram.com/reel/C7pdVg0ORy6/embed/",
      alt: "Post",
    },
    {
      src: "https://www.instagram.com/reel/C8ClqrDOJzh/embed/",
      alt: "Post",
    },
    {
      src: "https://www.instagram.com/reel/C0PwA_PuzAR/embed/",
      alt: "Post",
    },
    {
      src: "https://www.instagram.com/reel/CyV8fnnu2Ap/embed/",
      alt: "Post",
    },
  ];

  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const interval = setInterval(() => {
      slider.slickNext();
    }, 12000);

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    nextArrow: (
      <div>
        <div className="flex text-pink-700">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 hover:text-pink-500 transition-all duration-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="flex text-pink-700">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 hover:text-pink-500 transition-all duration-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="Instagram">
      <div className="bg-gradient-to-b from-[#CAF4FF] to-quinary h-8"></div>
      <div className="w-full py-10 sm:py-20 h-full bg-quinary">
        <h2 className="text-5xl sm:text-7xl font-extrabold pt-4 mb-4 text-pink-400 text-center sm:text-center w-2/3 mx-auto">
          Reels de Instagram
        </h2>
        <div className="w-9/12 sm:w-11/12 mx-auto my-8">
          <Slider ref={sliderRef} {...settings}>
            {links.map((post, index) => (
              <div key={index}>
                <iframe
                  src={post.src}
                  allow="autoplay; encrypted-media"
                  title={post.alt}
                  className="sm:w-4/5 w-11/12 rounded-lg mx-auto h-[25rem] sm:h-[35rem]"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Instagram;
