import React, { useEffect } from "react";
import Navbar from "../Components/NavbarPages";
import Footer from "../Components/Footer";

function AboutPage() {
  // const images = [
  //   "/images/people1.jpg",
  //   "/images/people2.jpg",
  //   "/images/people4.jpg",
  //   "/images/woman4.jpg",
  // ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar currentPage="about" />
      <div className="flex justify-center pt-28 flex-col rounded-lg p-8">
        <h1 className="text-5xl sm:text-6xl uppercase font-black text-pink-500 text-center mt-4 sm:mt-8">
          Sobre Nosotros
        </h1>

        {/* Grid with 4 photos */}
        {/* <div className="grid grid-cols-2 gap-4 sm:w-1/2 mx-auto">
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt="People" className="w-full rounded-xl" />
            </div>
          ))}
        </div> */}

        <p className="text-2xl text-gray-700 text-center w-full sm:w-1/2 mx-auto my-8 bg-slate-100 p-4 sm:p-8 rounded-xl ">
          Pioneros en México en la venta de máquinas de algodón de azúcar
          automatizadas. Somos una empresa dedicada a la elaboración de algodón
          de azúcar artesanal, con una gran variedad de sabores y formas,
          elaborado con azúcar de alta calidad y presentado de manera atractiva
          para nuestros clientes.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 sm:full mx-auto">
          <div className="bg-pink-300 rounded-xl p-8">
            <h2 className="text-4xl sm:text-5xl uppercase font-black text-pink-500 text-center mb-4">
              Nuestra Misión
            </h2>
            <p className="text-2xl text-gray-700 text-center w-full sm:w-2/3 mx-auto bg-slate-50 rounded-xl p-4">
              Ofrecer un producto de calidad, con un sabor único y una
              presentación atractiva para nuestros clientes, para que disfruten
              de un momento dulce y agradable.
            </p>
          </div>

          <div className="bg-pink-200 sm:bg-pink-300 rounded-xl p-8">
            <h2 className="text-4xl sm:text-5xl uppercase font-black text-pink-500 text-center mb-4">
              Nuestra Visión
            </h2>
            <p className="text-2xl text-gray-700 text-center w-full sm:w-2/3 mx-auto bg-slate-50 rounded-xl p-4">
              Ser una empresa reconocida a nivel nacional por la calidad de
              nuestro producto y la satisfacción de nuestros clientes.
            </p>
          </div>

          <div className="sm:bg-pink-200 bg-pink-300 rounded-xl p-8">
            <h2 className="text-4xl sm:text-5xl uppercase font-black text-pink-500 text-center mb-4">
              Nuestros Valores
            </h2>

            <p className="text-2xl text-gray-700 text-center w-full sm:w-2/3 mx-auto bg-slate-50 rounded-xl p-4">
              <ul className="list-none">
                <li>Entusiasmo</li>
                <li>Humildad</li>
                <li>Sinceridad</li>
                <li>Creatividad</li>
                <li>Innovación</li>
              </ul>
            </p>
          </div>

          <div className="bg-pink-200 rounded-xl p-8">
            <h2 className="text-4xl sm:text-5xl uppercase font-black text-pink-500 text-center mb-4">
              Nuestro Equipo
            </h2>
            <p className="text-2xl text-gray-700 text-center w-full sm:w-2/3 mx-auto bg-slate-50 rounded-xl p-4">
              Somos un equipo de profesionales, que juntos nos hemos
              comprometido por la absoluta satisfacción de nuestros clientes,
              haciendo de nuestro producto, máquinas y marca una experiencia
              divertida y única.
            </p>
          </div>

          <div className="bg-pink-300 sm:bg-pink-100 rounded-xl p-8">
            <h2 className="text-4xl sm:text-5xl uppercase font-black text-pink-500 text-center mb-4">
              Nuestra Historia
            </h2>
            <p className="text-2xl text-gray-700 text-center w-full sm:w-2/3 mx-auto bg-slate-50 rounded-xl p-4">
              Magic Cotton Candy nace en el año 2021, en medio de la pandemia,
              con el objetivo de brindar un producto de calidad y satisfacción a
              nuestros clientes.
            </p>
          </div>

          <div className="bg-pink-200 sm:bg-pink-100 rounded-xl p-8">
            <h2 className="text-4xl sm:text-5xl uppercase font-black text-pink-500 text-center mb-4">
              Nuestro Producto
            </h2>
            <p className="text-2xl text-gray-700 text-center w-full sm:w-2/3 mx-auto bg-slate-50 rounded-xl p-4">
              Ofrecemos experiencias que producen emociones mediante la venta de
              un delicioso algodón de azúcar en un formato automatizado,
              personalizado y divertido.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutPage;
