import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const visitPage = (url) => {
    window.open(url, "_blank");
  };

  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h3 className="text-xl font-bold mb-2">Magic Cotton Candy</h3>
            <p className="text-sm">Endulzando tus momentos desde 2023</p>
          </div>
          <div className="w-full md:w-1/3 mb-4 md:mb-0 text-center">
            <nav>
              <ul className="flex flex-wrap justify-center">
                <li className="mr-4 mb-2">
                  <Link
                    to="/"
                    className="hover:text-pink-300 transition duration-300"
                  >
                    Inicio
                  </Link>
                </li>
                <li className="mr-4 mb-2">
                  <Link
                    to="/negocio"
                    className="hover:text-pink-300 transition duration-300"
                  >
                    Negocio
                  </Link>
                </li>
                <li className="mr-4 mb-2">
                  <Link
                    to="/contacto"
                    className="hover:text-pink-300 transition duration-300"
                  >
                    Contacto
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="w-full md:w-1/3 text-center md:text-right">
            <Link
              to="/politica-de-privacidad"
              className="bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Política de Privacidad
            </Link>
          </div>
        </div>
        <div className="border-t border-gray-700 mt-6 pt-6 text-center">
          <div className="flex justify-center space-x-4 mb-4">
            <button
              className="text-2xl hover:text-pink-400 transition duration-300"
              onClick={() =>
                visitPage("https://instagram.com/magic_cotton_candy_mx/")
              }
            >
              <i className="fab fa-instagram"></i>
            </button>
            <button
              className="text-2xl hover:text-green-400 transition duration-300"
              onClick={() => visitPage("https://wa.me/5219841829727")}
            >
              <i className="fab fa-whatsapp"></i>
            </button>
            <button
              className="text-2xl hover:text-blue-400 transition duration-300"
              onClick={() =>
                visitPage(
                  "https://www.facebook.com/profile.php?id=61551403854725&mibextid=LQQJ4d"
                )
              }
            >
              <i className="fab fa-facebook-f"></i>
            </button>
            <button
              className="text-2xl hover:text-red-400 transition duration-300"
              onClick={() =>
                visitPage(
                  "mailto:contacto@magiccottoncandy.mx?subject=Contacto%20desde%20Magic%20Cotton%20Candy%20Web&body=Hola%20Magic%20Cotton%20Candy%2C%20me%20gustaría%20saber%20más%20sobre%20sus%20productos%20y%20servicios."
                )
              }
            >
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </button>
          </div>
          <p className="text-sm">
            &copy; {new Date().getFullYear()} Magic Cotton Candy. Todos los
            derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
