import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const Models = () => {
  // const images = [
  //   {
  //     src: "/images/cottons/cotton2.png",
  //     alt: "Gallery",
  //   },
  //   {
  //     src: "/images/cottons/cotton3.png",
  //     alt: "Gallery",
  //   },
  //   {
  //     src: "/images/cottons/cotton4.png",
  //     alt: "Gallery",
  //   },
  //   {
  //     src: "/images/cottons/cotton5.png",
  //     alt: "Gallery",
  //   },
  //   {
  //     src: "/images/cottons/cotton6.png",
  //     alt: "Gallery",
  //   },
  //   {
  //     src: "/images/cottons/cotton7.png",
  //     alt: "Gallery",
  //   },
  //   {
  //     src: "/images/cottons/cotton8.png",
  //     alt: "Gallery",
  //   },
  //   {
  //     src: "/images/cottons/cotton9.png",
  //     alt: "Gallery",
  //   },
  //   {
  //     src: "/images/cottons/cotton10.png",
  //     alt: "Gallery",
  //   },
  // ];

  const images2 = [
    {
      src: "/images/flores/Flor.png",
      alt: "Gallery",
    },
    {
      src: "/images/flores/Flor2.png",
      alt: "Gallery",
    },
    {
      src: "/images/flores/Flor3.png",
      alt: "Gallery",
    },
    {
      src: "/images/flores/Flor4.png",
      alt: "Gallery",
    },
    {
      src: "/images/flores/Flor5.png",
      alt: "Gallery",
    },
    {
      src: "/images/flores/Flor6.png",
      alt: "Gallery",
    },
    {
      src: "/images/flores/Flor8.png",
      alt: "Gallery",
    },
    {
      src: "/images/flores/Flor10.png",
      alt: "Gallery",
    },
    {
      src: "/images/flores/Flor11.png",
      alt: "Gallery",
    },
    {
      src: "/images/flores/Flor12.png",
      alt: "Gallery",
    },
  ];

  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const interval = setInterval(() => {
      slider.slickNext();
    }, 6000); // slide duration

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    nextArrow: (
      <div>
        <div className="flex text-pink-700">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 hover:text-pink-500 transition-all duration-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="flex text-pink-700">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 hover:text-pink-500 transition-all duration-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="models">
      <div className="bg-gradient-to-b from-tertiary to-white h-8"></div>
      <div className="w-full py-10 sm:py-20 h-full">
        <h2 className="text-5xl sm:text-7xl font-extrabold pt-4 mb-4 text-pink-400 text-center sm:text-center w-2/3 mx-auto">
          Nuestros modelos
        </h2>
        <div className="w-9/12 sm:w-11/12 mx-auto my-8">
          <Slider ref={sliderRef} {...settings} className="h-full flex">
            {images2.map((image, index) => (
              <div
                key={index}
                className="relative overflow-hidden rounded-lg h-full"
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="flex w-4/5 sm:w-3/4 sm:h-full transition duration-300 ease-in-out transform hover:scale-105 object-cover mx-auto"
                />

                <Link to="/modelos">
                  <div className="absolute inset-0 bg-pink-100 opacity-0 hover:opacity-75 transition-opacity">
                    <div className="flex items-center justify-center h-full">
                      <button
                        className="px-4 py-2 text-white bg-pink-800 rounded-lg hover:bg-pink-700 transition-colors"
                        href="/products"
                      >
                        Ver más
                      </button>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
        <div className="flex justify-center items-center">
          <Link to="/modelos">
            <button className="relative px-5 py-2 font-medium text-white group mt-10 transition-all duration-300 ease-out transform hover:shadow-xl hover:-translate-y-0.5 hover:scale-105">
              <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-pink-500 group-hover:bg-pink-700 group-hover:skew-x-12 rounded-xl"></span>
              <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-pink-700 group-hover:bg-pink-500 group-hover:-skew-x-12 rounded-xl"></span>
              <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-pink-600 -rotate-12 rounded-xl"></span>
              <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-pink-400 -rotate-12 rounded-xl "></span>
              <span className="relative text-2xl">Ver más</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Models;
