import React, { useEffect } from "react";
import Navbar from "../Components/NavbarPages";
import Footer from "../Components/Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar currentPage="privacy" />
      <div className="flex justify-center pt-28 flex-col rounded-lg p-8">
        <h1 className="text-5xl sm:text-6xl uppercase font-black text-pink-500 text-center mt-4 sm:mt-8 mb-8">
          Aviso de Privacidad
        </h1>

        <div className="bg-slate-100 p-6 sm:p-10 rounded-xl mb-8 text-gray-700">
          <p className="text-xl mb-6">
            De conformidad con lo previsto en la Legislación aplicable y vigente
            en la materia, específicamente en la Ley Federal de Protección de
            Datos Personales en Posesión de los Particulares (en lo sucesivo La
            Ley) y su Reglamento, se hace de su conocimiento que la empresa{" "}
            <strong className="text-pink-500">MAGIC COTTON, S.A.S.</strong>, en
            lo sucesivo referida como{" "}
            <strong className="text-pink-500">"MAGIC COTTON"</strong>, con
            domicilio en Calle Parque Real #805, Colonia Selvamar, Playa del
            Carmen, Quintana Roo, es responsable del uso, protección y
            tratamiento de sus datos personales.
          </p>

          <p className="text-xl mb-6">
            Por tal motivo, ha adoptado las medidas de seguridad administrativa,
            técnica y física necesarias, a fin de proteger los datos personales
            de sus, asociados, aliados estratégicos, personal, clientes,
            distribuidores, proveedores y colaboradores, así como de cualquier
            persona que tenga relación con{" "}
            <strong className="text-pink-500">"MAGIC COTTON"</strong>, y que
            acceda al siguiente sitio web oficial:{" "}
            <a
              href="https://www.magiccottoncandy.mx/"
              className="text-blue-600 hover:underline"
            >
              https://www.magiccottoncandy.mx/
            </a>
          </p>

          <p className="text-xl mb-6">
            Lo anterior como una medida que tenga por objeto evitar el daño,
            pérdida, alteración, destrucción, uso incorrecto, acceso,
            divulgación indebida o tratamiento diverso no autorizado, de los
            datos personales que, con motivo de su operación, administración,
            objeto social, actividad comercial, así como de los servicios
            contratados y brindados, se le requieran.
          </p>

          <h2 className="text-3xl font-bold text-pink-500 mt-8 mb-4">
            Datos Personales
          </h2>
          <p className="text-xl mb-6">
            Los datos personales, en lo sucesivo <strong>"LOS DATOS"</strong>,
            que sean solicitados a través de contratos, correos electrónicos,
            llamadas telefónicas, solicitudes, formularios y/o cualquier otro
            medio, sea físico o digital, podrán consistir de manera enunciativa
            más no limitativa, en los siguientes:
          </p>
          <ul className="list-disc list-inside text-xl mb-6 pl-4">
            <li>Nombre completo</li>
            <li>Domicilio completo</li>
            <li>Teléfono fijo</li>
            <li>Teléfono celular</li>
            <li>Correo electrónico</li>
            <li>Registro Federal de Contribuyentes (RFC)</li>
            <li>Clave Única de Registro (CURP)</li>
            <li>Datos financieros o bancarios</li>
            <li>
              Cualquier otro dato que a consideración de{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong> esté
              relacionado y sea necesario para llevar a cabo la prestación de
              servicios correspondientes.
            </li>
          </ul>

          <h2 className="text-3xl font-bold text-pink-500 mt-8 mb-4">
            Uso de Datos
          </h2>
          <p className="text-xl mb-6">
            <strong>"LOS DATOS"</strong> serán usados, tratados y protegidos de
            acuerdo a lo establecido por la Ley Federal de Protección de Datos
            Personales en Posesión de los Particulares y su Reglamento.
            Asimismo, serán concentrados en una base de datos y serán utilizados
            para identificarle en la integración, las operaciones, las
            relaciones administrativas y/o las relaciones comerciales que
            realice o establezca con{" "}
            <strong className="text-pink-500">"MAGIC COTTON"</strong>, así como
            también para fines de mercadotecnia, con la finalidad de que{" "}
            <strong className="text-pink-500">"MAGIC COTTON"</strong>, por sí o
            por algún tercero, pueda desarrollar las actividades necesarias para
            el cumplimiento de su objeto social y/o comercial.
          </p>

          <p className="text-xl mb-6">
            En caso de que se formalice la integración como miembro o usuario de
            <strong className="text-pink-500">"MAGIC COTTON"</strong>, o bien la
            aceptación de algún servicio ofrecido por
            <strong className="text-pink-500">"MAGIC COTTON"</strong>, "LOS
            DATOS" serán utilizados para el cumplimiento de las obligaciones
            derivadas de la relación jurídica y/o comercial que se configure;
            sin embargo,{" "}
            <strong className="text-pink-500">"MAGIC COTTON"</strong> garantiza
            que, salvo orden judicial debidamente expedida por autoridad
            competente o en caso de algún siniestro o situación de emergencia
            que potencialmente pueda dañar o dañen al usuario de la plataforma{" "}
            <strong className="text-pink-500">"MAGIC COTTON"</strong> o sus
            bienes, "LOS DATOS" no serán compartidos a ninguna persona, sea
            física o moral, nacional o extranjera, organismo u organización que
            sea distinta y/o ajena a{" "}
            <strong className="text-pink-500">"MAGIC COTTON"</strong> con la
            excepción de las sociedades subsidiarias, filiales, afiliadas y
            controladoras de MAGIC COTTON, S.A.S. de conformidad con el artículo
            37 de la Ley, incluidas las demás excepciones previstas en dicho
            ordenamiento legal.
          </p>

          <p className="text-xl mb-6">
            <strong className="text-pink-500">"MAGIC COTTON"</strong> en ningún
            momento le solicitará datos relacionados con origen racial, estado
            de salud, creencias religiosas o filosóficas, preferencias sexuales
            o afiliaciones políticas.
          </p>

          <h2 className="text-3xl font-bold text-pink-500 mt-8 mb-4">
            Finalidades necesarias
          </h2>
          <ol className="list-decimal list-inside text-xl mb-6 pl-4">
            <li>
              Registro en nuestros sistemas de información y base de datos de
              los clientes con los cuales{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong> mantiene
              relaciones comerciales, con el objeto de tener control sobre los
              productos o servicios que se adquieren.
            </li>
            <li>
              Proveer los productos o servicios que sean adquiridos a través del
              sitio web https://www.magiccottoncandy.mx/, así como para
              identificar, ubicar, contactar y/o enviar información que permita
              la comunicación entre los usuarios, miembros, clientes y
              consumidores de{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>.
            </li>
            <li>
              Proporcionar, personalizar y mejorar los productos y/o servicios
              que brinda{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>.
            </li>
            <li>
              Facturación y cobranza por los productos y/o servicios que brinda
              y comercializa{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>; así
              como administración y seguimiento a los servicios que sean
              solicitados.
            </li>
            <li>
              Elaboración de órdenes de compra y atención de solicitudes de
              servicios a través de cualquiera de los canales y/o medios de
              comunicación de{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>.
            </li>
            <li>
              Elaboración de Contratos Comerciales y asignación de crédito (en
              caso de ser solicitado).
            </li>
            <li>
              Usar los datos, antecedentes, conversaciones, grabaciones de
              llamadas y actividades que se generen con motivo de los servicios
              adquiridos de{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>, para
              brindar un mejor servicio, así como proporcionar asistencia en
              caso de que se requiera para resolver cualquier duda o reclamo.
            </li>
            <li>
              Actualizar nuestras bases de datos y conservar su información para
              dar cumplimiento a los ordenamientos legales y a las disposiciones
              normativas correspondientes, a fin de cumplir con los
              requerimientos de las autoridades y de las entidades regulatorias.
            </li>
            <li>
              Atender sus dudas, quejas, reclamaciones, sugerencias y
              aclaraciones; así como brindar información sobre las
              actualizaciones que se lleguen a realizar respecto de los
              productos/servicios que son puestos a disposición del público por{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>.
            </li>
            <li>
              Enviar comunicaciones, relacionados con los productos y/o
              servicios que han adquirido y/o contratado de{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong> a través
              de su sitio web, sus establecimientos físicos, sucursales y en
              general por cualquier medio controlado y operado por{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>.
            </li>
            <li>
              Dar seguimiento y realizar encuestas con la finalidad de medir,
              evaluar y documentar la calidad de nuestros servicios y de
              nuestros medios electrónicos, para en su caso, realizar las
              mejoras que correspondan.
            </li>
            <li>Realizar y enviar cotizaciones.</li>
            <li>Realización de investigaciones y estudios de mercado.</li>
            <li>
              Informar sobre posibles cambios en las políticas de{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>; así
              como respecto a las actualizaciones que se susciten en las
              características de los servicios brindados por{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>.
            </li>
            <li>
              Generación de un control y seguimiento de quejas, sugerencias o
              peticiones sobre nuestros servicios.
            </li>
          </ol>

          <h2 className="text-3xl font-bold text-pink-500 mt-8 mb-4">
            Finalidades no necesarias
          </h2>
          <ol className="list-decimal list-inside text-xl mb-6 pl-4">
            <li>
              Envío de publicidad por correo electrónico, banners, dispositivos
              móviles y otros medios electrónicos.
            </li>
            <li>
              Notificación sobre nuevos eventos, proyectos, servicios y/o
              productos que tengan relación con los ya contratados y/o
              adquiridos por el cliente, o bien con el objeto social de{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>.
            </li>
            <li>
              Envío de ofertas, avisos y/o mensajes promocionales y
              publicitarios relacionados con los productos y servicios que
              ofrece <strong className="text-pink-500">"MAGIC COTTON"</strong>.
            </li>
            <li>
              Envío de correos con eventos organizados y/o recomendados por{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>.
            </li>
            <li>
              Envío de noticias, así como beneficios y servicios ofrecidos por
              nuestros <strong className="text-pink-500">"MAGIC COTTON"</strong>{" "}
              y/o socios comerciales.
            </li>
          </ol>

          <p className="text-xl mb-6">
            En caso de que no desee que sus datos personales sean tratados para
            estos fines adicionales, no necesarios, usted puede presentar en
            cualquier momento una solicitud manifestando expresamente su
            negativa siguiendo el procedimiento descrito en el apartado
            denominado "derechos arco" de este documento.
          </p>

          <h2 className="text-3xl font-bold text-pink-500 mt-8 mb-4">
            Derechos ARCO
          </h2>
          <p className="text-xl mb-6">
            Es importante informarle que usted tiene derecho de acceder,
            rectificar y cancelar sus datos personales, así como de oponerse al
            tratamiento de los mismos o revocar el consentimiento para el fin
            que se haya otorgado. Estos derechos se conocen como derechos ARCO.
            Usted tiene derecho a:
          </p>
          <ul className="list-disc list-inside text-xl mb-6 pl-4">
            <li>
              <strong>Derecho de Acceso:</strong> Es posible cuando el titular
              desea conocer cuáles de sus datos están sujetos a tratamiento por
              el responsable, el origen de los mismos, las comunicaciones que se
              han llevado a cabo, las finalidades del tratamiento, el aviso de
              privacidad que rige el tratamiento y demás condiciones y
              generalidades del tratamiento.
            </li>
            <li>
              <strong>Derecho de Rectificación:</strong> Procede cuando existen
              datos inexactos o incompletos en las bases de datos{" "}
              <strong className="text-pink-500">"MAGIC COTTON"</strong>. El
              titular de los datos deberá indicar los datos que se deban
              corregir y aportar la documentación que sustente su petición.
            </li>
            <li>
              <strong>Derecho de Cancelación:</strong> Procede cuando los datos
              personales hayan dejado de ser necesarios para el cumplimiento de
              las finalidades para las cuales fueron recabados.
            </li>
            <li>
              <strong>Derecho de Oposición:</strong> El titular podrá oponerse
              al tratamiento de sus datos personales en determinadas situaciones
              (por ejemplo: para fines mercadológicos, publicitarios, estudios
              de mercado, prospección comercial, entre otros).
            </li>
            <li>
              <strong>Derecho de Revocación del Consentimiento:</strong> Para
              interrumpir el tratamiento de los datos personales, y hacer del
              conocimiento a los encargados dicha revocación, para que efectúen
              lo conducente.
            </li>
          </ul>

          <p className="text-xl mb-6">
            Para ejercer estos derechos, usted puede enviar una solicitud a:
          </p>
          <ul className="list-disc list-inside text-xl mb-6 pl-4">
            <li>
              Por correo postal: Calle Parque Real #805, Colonia Selvamar, Playa
              del Carmen, Quintana Roo.
            </li>
            <li>
              Por correo electrónico:{" "}
              <a
                href="mailto:contacto@magiccottoncandy.mx"
                className="text-blue-600 hover:underline"
              >
                contacto@magiccottoncandy.mx
              </a>
            </li>
          </ul>

          <h2 className="text-3xl font-bold text-pink-500 mt-8 mb-4">
            Uso de cookies, web beacons, y otras tecnologías
          </h2>
          <p className="text-xl mb-6">
            El sitio web{" "}
            <a
              href="https://www.magiccottoncandy.mx/"
              className="text-blue-600 hover:underline"
            >
              https://www.magiccottoncandy.mx/
            </a>{" "}
            utiliza cookies, web beacons, y otras tecnologías a través de las
            cuales es posible reconocer a los usuarios habituales y rastrear su
            comportamiento de uso en nuestra página web, personalizar la
            información y las publicidades para brindarle un mejor servicio y
            experiencia al navegar en nuestro sitio, así como ofrecerle nuevos
            productos y servicios basados en sus preferencias.
          </p>

          <h2 className="text-3xl font-bold text-pink-500 mt-8 mb-4">
            Transferencia de datos personales
          </h2>
          <p className="text-xl mb-6">
            <strong>"LOS DATOS"</strong> única y estrictamente podrán ser
            transferidos a terceros en los casos y bajo las circunstancias
            previstas en la Ley Federal de Protección de Datos Personales en
            Posesión de Particulares, según lo establecido en los artículos 10 y
            37 de la multicitada Ley.
          </p>

          <h2 className="text-3xl font-bold text-pink-500 mt-8 mb-4">
            Modificaciones al Aviso de Privacidad
          </h2>
          <p className="text-xl mb-6">
            <strong className="text-pink-500">"MAGIC COTTON"</strong> se reserva
            el derecho de efectuar en cualquier momento modificaciones o
            actualizaciones al presente Aviso de Privacidad, para la atención de
            novedades legislativas, políticas internas o nuevos requerimientos
            que sean necesarios para continuar con la relación contractual o
            seguir proporcionándole nuestros servicios. Estas modificaciones
            estarán disponibles al público a través de los siguientes medios:
          </p>
          <ul className="list-disc list-inside text-xl mb-6 pl-4">
            <li>
              A través de internet en nuestro sitio web:{" "}
              <a
                href="https://www.magiccottoncandy.mx/"
                className="text-blue-600 hover:underline"
              >
                https://www.magiccottoncandy.mx/
              </a>
            </li>
            <li>
              Vía correo electrónico a{" "}
              <a
                href="mailto:contacto@magiccottoncandy.mx"
                className="text-blue-600 hover:underline"
              >
                contacto@magiccottoncandy.mx
              </a>
            </li>
          </ul>

          <p className="text-xl mt-8">
            Si usted considera que su derecho de protección de datos personales
            ha sido lesionado por alguna conducta de nuestros colaboradores o de
            nuestras actuaciones o respuestas, o presume que en el tratamiento
            de sus datos personales existe alguna violación a las disposiciones
            previstas en la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares, podrá interponer la queja o denuncia
            correspondiente ante el Instituto Nacional de Transparencia, Acceso
            a la Información y Protección de Datos Personales (INAI). Para mayor
            información visite la página web oficial:{" "}
            <a
              href="http://inicio.inai.org.mx"
              className="text-blue-600 hover:underline"
            >
              http://inicio.inai.org.mx
            </a>
          </p>

          <p className="text-xl font-bold mt-8">
            Última actualización: 12 de julio de 2024
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
