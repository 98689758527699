import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import "./Navbar.css";

function Navbar(currentPage) {
  const [showNavbar, setShowNavbar] = useState(true);
  const [showMenuToggle, setShowMenuToggle] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        setShowNavbar(false);
        setShowMenuToggle(true);
      } else {
        setShowNavbar(true);
        setShowMenuToggle(false);
        setShowMenu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div id="navbar">
      <div
        className={`font-extrabold text-lg sm:text-2xl 2xl:text-4xl flex justify-center p-6 sm:py-6 sm:px-32 mb-6 shadow-md fixed top-0 w-full bg-pink-100 bg-opacity-80 z-30 transition-all duration-500 ${
          showNavbar ? "opacity-100 animate-slide-up" : "opacity-0 absolute"
        }`}
      >
        {/* <div className="cursor-pointer">
          <img
            src="/images/truck2.png"
            alt="Cotton Candy"
            className="w-1/4 sm:w-12"
            href="#home"
          />
        </div> */}
        <ul className="flex flex-row gap-6 items-center">
          <li>
            <RouterLink
              className="text-pink-500 hover:font-bold cursor-pointer"
              to="/"
            >
              Inicio
            </RouterLink>
          </li>

          {/* If current page is about, don't show about link */}
          {currentPage.currentPage !== "about" && (
            <li>
              <RouterLink
                className="text-pink-500 hover:font-bold cursor-pointer"
                to="/nosotros"
              >
                Nosotros
              </RouterLink>
            </li>
          )}

          {/* If current page is models, don't show models link */}
          {currentPage.currentPage !== "models" && (
            <li>
              <RouterLink
                className="text-pink-500 hover:font-bold cursor-pointer"
                to="/modelos"
              >
                Modelos
              </RouterLink>
            </li>
          )}

          {currentPage.currentPage !== "franchise" && (
            <li>
              <RouterLink
                className="text-pink-500 hover:font-bold cursor-pointer"
                to="/negocio"
              >
                Negocio
              </RouterLink>
            </li>
          )}

          {currentPage.currentPage !== "contact" && (
            <li>
              <RouterLink
                className="text-pink-500 hover:font-bold cursor-pointer"
                to="/contacto"
              >
                Contacto
              </RouterLink>
            </li>
          )}
        </ul>
      </div>
      <div
        className={`font-bold text-lg sm:p-6 mb-6 fixed top-0 right-0 z-20 transition-all duration-200 ${
          showMenuToggle
            ? "animate-slide-left opacity-100 "
            : "animate-slide-right opacity-0"
        }`}
      >
        <button
          className="text-pink-50 bg-pink-500 hover:bg-pink-600 rounded-md p-2 sm:p-4 2xl:p-6 m-4 transition duration-200 ease-in-out"
          onClick={() => setShowMenu(!showMenu)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
        <div
          className={`fixed text-2xl sm:text-3xl top-20 sm:top-32 right-4 sm:right-10 text-pink-50 bg-pink-500 p-2 sm:p-6 w-11/12 sm:w-80 rounded-md shadow-lg transition-all duration-200 ${
            showMenu
              ? "animate-slide-left opacity-100"
              : "animate-slide-right translate-x-full opacity-0"
          }`}
        >
          <ul className="flex flex-col gap-6 sm:ml-6 mb-10">
            <li className="flex justify-end">
              <button
                className="text-indigo-100 hover:text-white "
                onClick={() => setShowMenu(!showMenu)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-10 h-10 hover:rotate-180 transition duration-500 ease-in-out"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </li>
            <li className="flex justify-center sm:justify-start">
              <RouterLink
                className="text-indigo-100 hover:font-bold hover:text-white cursor-pointer"
                to="/"
                onClick={handleMenuToggle}
              >
                Inicio
              </RouterLink>
            </li>
            <li className="flex justify-center sm:justify-start">
              <RouterLink
                className="text-indigo-100 hover:font-bold hover:text-white  cursor-pointer"
                to="/nostros"
                onClick={handleMenuToggle}
              >
                Nosotros
              </RouterLink>
            </li>
            <li className="flex justify-center sm:justify-start">
              <RouterLink
                className="text-indigo-100 hover:font-bold hover:text-white  cursor-pointer"
                to="/modelos"
                onClick={handleMenuToggle}
              >
                Modelos
              </RouterLink>
            </li>
            <li className="flex justify-center sm:justify-start">
              <RouterLink
                className="text-indigo-100 hover:font-bold hover:text-white  cursor-pointer"
                to="/contacto"
                onClick={handleMenuToggle}
              >
                Contacto
              </RouterLink>
            </li>
            <li className="flex justify-center sm:justify-start">
              <RouterLink
                className="text-indigo-100 hover:font-bold hover:text-white  cursor-pointer"
                to="/negocio"
                onClick={handleMenuToggle}
              >
                Negocio
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
