import React from "react";
import { Link } from "react-router-dom";

function Contact() {
  const visitPage = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div id="contact">
      <div className="bg-gradient-to-b from-pink-100 to-quaternary h-8"></div>
      <div className="text-center bg-quaternary pb-10 sm:py-20">
        <div className="flex justify-center items-center flex-col sm:flex-row sm:gap-12">
          <div className=" p-4 sm:text-left">
            <h2 className="text-5xl sm:text-7xl font-extrabold pt-4 pb-2 sm:pb-12 text-pink-400 text-center sm:text-center">
              Contáctanos
            </h2>
            <div className="relative h-32 w-80 rounded-full p-4 my-20 mx-auto">
              <div className="absolute h-40 w-40 sm:h-48 sm:w-48 bg-white rounded-full -top-4 -left-6 sm:-top-10 sm:-left-20"></div>
              <div className="absolute h-40 w-40 sm:h-48 sm:w-48 bg-white rounded-full top-4 -right-2 sm:top-6 sm:-right-2"></div>
              <div className="absolute h-40 w-40 sm:h-48 sm:w-48 bg-white rounded-full bottom-6 left-44"></div>
              <div className="absolute h-40 w-40 sm:h-48 sm:w-48 bg-white rounded-full -bottom-16 right-24 sm:-bottom-22 sm:right-32"></div>
              <div className="absolute h-40 w-40 sm:h-48 sm:w-48 bg-white rounded-full bottom-8 right-24 sm:bottom-3"></div>
              <div className="absolute h-40 w-40 sm:h-48 sm:w-48 bg-white rounded-full top-4 -right-2 sm:-top-10 sm:left-56 hidden sm:block"></div>
              <div className="absolute inset-0 flex items-center justify-center text-pink-300 font-bold text-2xl sm:text-3xl text-center p-4 sm:p-0">
                Envíanos un mensaje a través de nuestras redes sociales y
                responderemos lo más pronto posible.
              </div>
            </div>
            {/* <div className="relative bg-white rounded-2xl shadow-md p-4 mt-10 w-3/4 sm:w-1/2 mx-auto">
              <p className="text-2xl text-gray-700 text-center mx-auto">
                Envianos un mensaje a través de nuestras redes sociales y
                responderemos lo más pronto posible.
              </p>
            </div> */}
            <div className="mt-8 sm:mt-4 p-2 sm:p-8 gap-4 sm:gap-12 flex flex-row justify-center">
              <button
                id="instagram"
                className="border-2 flex items-center justify-center w-16 h-16 sm:w-36 sm:h-36 text-3xl sm:text-7xl hover:border-0 border-pink-500 bg-gradient-to-b  hover:from-indigo-600 hover:via-pink-600 hover:to-yellow-500 min-w-wull hover:text-white bg-white text-pink-600 transform hover:-translate-y-3 rounded-full duration-500"
                onClick={() =>
                  visitPage("https://instagram.com/magic_cotton_candy_mx/")
                }
              >
                <i className="fab fa-instagram"></i>
              </button>
              <button
                id="whatsapp"
                className="bg-white duration-500 w-16 h-16 sm:w-36 sm:h-36 text-4xl sm:text-7xl border-2 rounded-full border-green-600 transform hover:-translate-y-3 text-green-500 hover:bg-green-600 hover:text-white"
                onClick={() => visitPage("https://wa.me/5219841829727")}
              >
                <i className="fab fa-whatsapp"></i>
              </button>
              <button
                id="facebook"
                className="bg-white sticky duration-500 w-16 h-16 sm:w-36 sm:h-36 text-3xl sm:text-7xl border-2 border-blue-600 transform hover:-translate-y-3  rounded-full hover:bg-blue-600 hover:text-white text-blue-600"
                onClick={() =>
                  visitPage(
                    "https://www.facebook.com/profile.php?id=61551403854725&mibextid=LQQJ4d"
                  )
                }
              >
                <i className="fab fa-facebook-f"></i>
              </button>
              <button
                id="email"
                className="bg-white sticky duration-500 w-16 h-16 sm:w-36 sm:h-36 text-3xl sm:text-7xl border-2 border-red-600 transform hover:-translate-y-3  rounded-full hover:bg-red-600 hover:text-white text-red-600"
                onClick={() =>
                  visitPage(
                    "mailto:contacto@magiccottoncandy.mx?subject=Contacto%20desde%20Magic%20Cotton%20Candy%20Web&body=Hola%20Magic%20Cotton%20Candy%2C%20me%20gustaría%20saber%20más%20sobre%20sus%20productos%20y%20servicios."
                  )
                }
              >
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </button>
            </div>
            <Link to="/contacto" className="flex justify-center">
              <button className="relative px-5 py-2 font-medium text-white group mt-10 transition-all duration-300 ease-out transform hover:shadow-xl hover:-translate-y-0.5 hover:scale-105">
                <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-pink-500 group-hover:bg-pink-700 group-hover:skew-x-12 rounded-xl"></span>
                <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-pink-700 group-hover:bg-pink-500 group-hover:-skew-x-12 rounded-xl"></span>
                <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-pink-600 -rotate-12 rounded-xl"></span>
                <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-pink-400 -rotate-12 rounded-xl "></span>
                <span className="relative text-2xl">Enviar mensaje</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
